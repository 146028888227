import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { MarketProfileAppContext } from "../../../app.context";
import { Paragraph } from "gx-npm-ui";
import styles from "./market-products-header.styles.module.scss";
import classNames from "classnames";

const MarketProductsListHeader = () => {
  const { gcomMqResId, hasSelectedPriorities } = useContext(MarketProfileAppContext);
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        styles.headerRoot,
        gcomMqResId !== -1 && styles.hasMqInfo,
        hasSelectedPriorities && styles.hasVendorFit
      )}
    >
      <Paragraph boldness="medium" rootClassName={styles.vendorTitle} type="p4">
        {t("Vendor")}
      </Paragraph>
      <Fragment>
        {gcomMqResId !== -1 && (
          <Paragraph boldness="medium" type="p4">
            <span className="gx-sm-screen-size">{t("MQ position")}</span>
          </Paragraph>
        )}
      </Fragment>
      <Paragraph boldness="medium" type="p4" rootClassName={styles.peerInsightsTitle}>
        {t("Peer Insights")}
      </Paragraph>
      {hasSelectedPriorities && (
        <Paragraph boldness="medium" type="p4" rootClassName={styles.vendorFitTitle}>
          {t("Vendor fit")}
        </Paragraph>
      )}
    </div>
  );
};
export default MarketProductsListHeader;

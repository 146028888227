import React, { useContext } from "react";
import { useUserState, useCaptureEventsV2 } from "gx-npm-lib";
import {
  Paragraph,
  TypographyComponent,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOn,
  FeatureFlagBooleanOff,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { MarketProfileAppContext } from "../../app.context";
import styles from "./market-overview-section.styles.module.scss";
import MarketFeaturesTableComponent from "./market-features-table/market-features-table.component";
import classNames from "classnames";
import { ClientEvent } from "../../app.constants";

import { GCOM_3721__poweredByLinks } from "../../lib/feature-flag";

const MarketOverviewSectionComponent = () => {
  const { t } = useTranslation();
  const { hasUserState } = useUserState();
  const { description, gcomCcDocCd, gcomCcResId, isLoading, templateId, name } = useContext(MarketProfileAppContext);
  const hasTableView = gcomCcDocCd > 0;

  const captureEvents = useCaptureEventsV2();

  const handlePoweredByLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const eventType = ClientEvent.MARKET_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_LINK_CLICKED;
    const metaData = {
      templateId: templateId,
      templateName: name,
    };
    captureEvents([{ eventType: eventType, metaData: metaData }]);

    window.open(`https://www.gartner.com/document/${gcomCcResId}`, "_blank");
  };

  return (
    <section className={classNames(styles.sectionContainer)}>
      <div className={styles.cardV2}>
        <FeatureFlagBooleanContainer flagName={GCOM_3721__poweredByLinks}>
          <FeatureFlagBooleanOn>
            <div className={styles.aboutHeader}>
              <TypographyComponent
                rootClassName={styles.aboutParagraphV2}
                color={"carbon"}
                boldness={"semi"}
                styling={"p1"}
              >
                {t("About the market")}
              </TypographyComponent>
              {gcomCcResId !== -1 && (
                <div className={styles.poweredBy}>
                  <TypographyComponent rootClassName={styles.poweredByText} color={"iron"} styling={"p5"}>
                    {t("Powered by")}
                  </TypographyComponent>
                  <a
                    href={`https://www.gartner.com/document/${gcomCcResId}`}
                    onClick={handlePoweredByLinkClick}
                    target={"_blank"}
                    className={styles.poweredByLink}
                  >
                    <TypographyComponent rootClassName={styles.poweredByLinkText} boldness={"medium"} styling={"p5"}>
                      {t("Gartner Critical Capabilities")}
                    </TypographyComponent>
                  </a>
                </div>
              )}
            </div>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent rootClassName={styles.aboutParagraphV2} color={"carbon"} boldness={"semi"} type={"p1"}>
              {t("About the market")}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        <div
          className={classNames(
            hasTableView ? styles.detailsContainer : styles.detailsContainerNoTable,
            styles.detailsContainer
          )}
        >
          <Paragraph
            rootClassName={classNames(
              hasTableView ? styles.marketDescription : styles.marketDescriptionNoTable,
              styles.marketDescription
            )}
            type="p3"
          >
            {description}
          </Paragraph>
          <div className={styles.featuresDisplayContainer}>
            {hasTableView && hasUserState && !isLoading && <MarketFeaturesTableComponent />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MarketOverviewSectionComponent;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hb1RevB5UCwR8q3f_IWS{align-items:center;display:flex;white-space:nowrap}.Hb1RevB5UCwR8q3f_IWS .BCndXxu6_zBcT4KEriUk{margin-right:24px}.Hb1RevB5UCwR8q3f_IWS .BCndXxu6_zBcT4KEriUk .Ht_3ocvmYyjRYxMFzbJm{margin-left:6px}@media screen and (min-width: 1240px){.Hb1RevB5UCwR8q3f_IWS{flex-direction:row}.Hb1RevB5UCwR8q3f_IWS .BCndXxu6_zBcT4KEriUk{margin-bottom:0}}`, "",{"version":3,"sources":["webpack://./src/sections/header/header-action-buttons/header-action-buttons.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,kBAAA,CAEA,4CACE,iBAAA,CAEA,kEACE,eAAA,CAIJ,sCAbF,sBAcI,kBAAA,CAEA,4CACE,eAAA,CAAA","sourcesContent":[".headerActionsContainer {\n  align-items: center;\n  display: flex;\n  white-space: nowrap;\n\n  & .previewBtn {\n    margin-right: 24px;\n\n    & .previewBtnText {\n      margin-left: 6px;\n    }\n  }\n\n  @media screen and (min-width: 1240px) {\n    flex-direction: row;\n\n    & .previewBtn {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerActionsContainer": `Hb1RevB5UCwR8q3f_IWS`,
	"previewBtn": `BCndXxu6_zBcT4KEriUk`,
	"previewBtnText": `Ht_3ocvmYyjRYxMFzbJm`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Jc7mhst6Sg7NZSiI9uWJ{align-items:center;box-shadow:inset 0px -1px 0px var(--color-neutrals-silver);display:grid;grid-template-columns:60% 1fr;min-height:80px;padding-top:15px;padding-bottom:17px;padding-left:0}.Jc7mhst6Sg7NZSiI9uWJ.zjOy6FpzxvkZGB53gX3A{min-height:120px;padding-top:25px;padding-bottom:0}.Jc7mhst6Sg7NZSiI9uWJ.S3cRX7QFgsmFGVoJ5VvV{grid-template-columns:60% 1fr 1fr}.Jc7mhst6Sg7NZSiI9uWJ.S3cRX7QFgsmFGVoJ5VvV.YEm3iirKjvEoEOI2HTRQ{grid-template-columns:50% 1fr 1fr 1fr}.pdXPlDhe8zYmqkDNN4BW{width:40px}`, "",{"version":3,"sources":["webpack://./src/sections/market-products/product-row/product-row.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,0DAAA,CACA,YAAA,CACA,6BAAA,CACA,eAAA,CACA,gBAAA,CACA,mBAAA,CACA,cAAA,CAEA,2CACE,gBAAA,CACA,gBAAA,CACA,gBAAA,CAGF,2CACE,iCAAA,CAEA,gEACE,qCAAA,CAKN,sBACE,UAAA","sourcesContent":[".root {\n  align-items: center;\n  box-shadow: inset 0px -1px 0px var(--color-neutrals-silver);\n  display: grid;\n  grid-template-columns: 60% 1fr ;\n  min-height: 80px;\n  padding-top: 15px;\n  padding-bottom: 17px;\n  padding-left: 0;\n\n  &.ffGCOM3575 {\n    min-height: 120px;\n    padding-top: 25px;\n    padding-bottom: 0;\n  }\n\n  &.hasMqInfo{\n    grid-template-columns: 60% 1fr 1fr;\n\n    &.hasVendorFit {\n      grid-template-columns: 50% 1fr 1fr 1fr;\n    }\n  }\n}\n\n.endPadding {\n  width: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Jc7mhst6Sg7NZSiI9uWJ`,
	"ffGCOM3575": `zjOy6FpzxvkZGB53gX3A`,
	"hasMqInfo": `S3cRX7QFgsmFGVoJ5VvV`,
	"hasVendorFit": `YEm3iirKjvEoEOI2HTRQ`,
	"endPadding": `pdXPlDhe8zYmqkDNN4BW`
};
export default ___CSS_LOADER_EXPORT___;

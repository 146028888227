import classNames from "classnames";
import { MQTemplateResearchIcon } from "gx-npm-icons";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TextLink,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import React from "react";
import classes from "./magic-q-body.styles.module.scss";
import { useTranslation } from "react-i18next";
import { GCOM_3606__fontUpdate, GCOM_3695_ctaButtonColorUpdate } from "../../../feature-flags";
import { colorPalette } from "gx-npm-common-styles";

const MagicQBody = ({ documentQuery = -1, templateName = "" }) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);

  const { t } = useTranslation();
  return (
    <div className={classNames(classes.contentBodyMidSectionLeft)}>
      <h5
        className={classNames(
          classes.contentBodyMidSectionTitleText,
          isFFGCOM3695 && classes.contentBodyMidSectionTitleTextGCOM3695
        )}
      >
        <MQTemplateResearchIcon
          {...(isFFGCOM3695 && { fill: colorPalette.interactions.berry.hex })}
          {...(isFFGCOM3695 && { stroke: colorPalette.interactions.berry.hex })}
        />
        {t("Magic Quadrant")}
      </h5>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={classNames(classes.contentBodyMidSectionDescText)} element={"p"}>
            {t(
              "Magic Quadrants (MQs) offer visual snapshots and in-depth analyses that provide insight into a market's direction, maturity and participants. They help you:"
            )}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className={classNames(classes.contentBodyMidSectionDescText)}>
            {t(
              "Magic Quadrants (MQs) offer visual snapshots and in-depth analyses that provide insight into a market's direction, maturity and participants. They help you:"
            )}
          </p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <ul className={classNames(classes.contentBodyMidSectionList)}>
        <li className={classNames(classes.contentBodyMidSectionLeftListItem)}>
          {t("Map provider strengths and challenges against specific business requirements.")}
        </li>
        <li className={classNames(classes.contentBodyMidSectionLeftListItem)}>
          {t(
            "Gain fast insight into market imperatives and providers’ ability to deliver on what you require today and in the future."
          )}
        </li>
        <li className={classNames(classes.contentBodyMidSectionLeftListItem)}>
          {t(
            "Understand how a market’s providers are competitively positioned, and the strategies they are using to compete."
          )}
        </li>
      </ul>
      <div className={classNames(classes.contentLearnMoreLine)} />
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={classNames(classes.contentBodyMidSectionLearnMore)}>
            {t("Learn more")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className={classNames(classes.contentBodyMidSectionLearnMore)}>{t("Learn more")}</p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <div className={classNames(classes.contentLink)}>
        <TextLink
          rootClassName={classNames(classes.contentBodyMidSectionLinkText)}
          href={`https://www.gartner.com/document/${documentQuery}`}
          target="_blank"
          text={t(`Magic Quadrant for ${templateName}`)}
          variant={"dark"}
        />
      </div>
    </div>
  );
};

export default MagicQBody;

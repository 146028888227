import { ClickAwayListener, Popper } from "@material-ui/core";
import { CloseIcon } from "gx-npm-icons";
import { Paragraph, ProductLogo, useFeatureFlag } from "gx-npm-ui";
import React, { KeyboardEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import { MarketProfileAppContext } from "../../../../app.context";
import { TopProduct } from "../../../../app.types";
import styles from "./feature-description-popper.styles.module.scss";
import { GCOM_3695_ctaButtonColorUpdate } from "../../../../lib/feature-flag";
import classNames from "classnames";

type FeatureDescriptionPopperProps = {
  anchorEl: HTMLElement | null;
  description: string;
  isOpen: boolean;
  name: string;
  onClose: () => void;
  topProducts: TopProduct[];
};
const FeatureDescriptionPopper = ({
  anchorEl,
  description,
  isOpen,
  name,
  onClose,
  topProducts,
}: FeatureDescriptionPopperProps) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const { templateId } = useContext(MarketProfileAppContext);
  const { t } = useTranslation();

  const handleRef = (ref: HTMLElement | null) => {
    setArrowRef(ref);
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onClose();
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, url: string) => {
    event.preventDefault();
    singleSpa.navigateToUrl(url);
  };
  return (
    <Popper
      anchorEl={anchorEl}
      className={styles.root}
      modifiers={{
        arrow: { enabled: true, element: arrowRef },
        flip: { enabled: true },
        preventOverflow: { enabled: true, boundariesElement: "scrollParent" },
      }}
      open={isOpen}
      placement="bottom"
    >
      <ClickAwayListener onClickAway={onClose}>
        <section className={styles.sectionWrapper}>
          <div className={styles.nameWrapper}>
            <Paragraph type="p3" boldness="medium">
              {name}
            </Paragraph>
            <div
              aria-label={t("Close")}
              className={styles.closeBtn}
              onClick={onClose}
              onKeyDown={handleKeyDown}
              role="button"
              tabIndex={0}
            >
              <CloseIcon />
            </div>
          </div>
          <div className={styles.descriptionWrapper}>
            <Paragraph type="p4">{description}</Paragraph>
          </div>
          <div className={styles.dividerLine} />
          <Paragraph type="p4" rootClassName={styles.topProductsTitle}>
            {t("Top ranked vendor(s) in")}
            {` ${name}`}
          </Paragraph>
          <ul className={styles.topProductList}>
            {topProducts.map((product) => {
              const url = `/s/market/${templateId}/product/${product.id}`;

              return (
                <li className={styles.topProductItem} key={product.id}>
                  <a className={styles.productLink} href={url} onClick={(event) => handleClick(event, url)}>
                    <ProductLogo imageHeight="24px" imageWidth="24px" logo={product.imageLoc} name={product.name} />
                    <Paragraph
                      boldness="semi"
                      rootClassName={classNames(styles.productName, isFFGCOM3695 && styles.productNameGCOM3695)}
                      type="p3"
                    >
                      {product.name}
                    </Paragraph>
                  </a>
                </li>
              );
            })}
          </ul>
          <div className={styles.listFade} />
        </section>
      </ClickAwayListener>
      <span className={styles.gxPopperArrow} ref={handleRef} />
    </Popper>
  );
};

export default FeatureDescriptionPopper;

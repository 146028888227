import { FreeTrialRole, useUserState } from "gx-npm-lib";
import {
  ButtonLoader,
  FreeTrialInitCreateModalComponent,
  SnackbarBanner,
  TooltipV2,
  TypographyComponent,
} from "gx-npm-ui";
import { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MarketProfileAppContext } from "../../app.context";
import MGIconSVG from "../../assets/images/mg-icon.svg";
import MQIconSVG from "../../assets/images/mq-icon2.svg";
import { createInitiativeFromTemplateV2 } from "../../lib/requests.lib";
import styles from "./footer.styles.module.scss";

const Footer = () => {
  const { t } = useTranslation();
  const {
    checklistCount,
    gcomMgResId,
    gcomMqResId,
    isProcessing,
    name,
    productCount,
    requirementCount,
    setIsProcessing,
    templateId,
    userInitCount,
  } = useContext(MarketProfileAppContext);
  const { freeTrialRole, isEntitled } = useUserState();
  const [isError, setIsError] = useState(false);
  const [isFreeTrialModalOpen, setIsFreeTrialModalOpen] = useState(false);
  const isTrialUser = !!freeTrialRole && freeTrialRole === FreeTrialRole.FREE_TRIAL_RECIPIENT;

  const handleOnError = () => {
    setIsError(true);
    setIsProcessing(false);
  };

  const handleErrorClose = () => {
    setIsError(false);
  };

  const handleStartEvalClick = async () => {
    if (isTrialUser) {
      setIsFreeTrialModalOpen(true);
    } else {
      setIsProcessing(true);
      await createInitiativeFromTemplateV2({ templateId, templateName: name }, handleOnError);
    }
  };

  const handleCloseFreeTrialModal = () => {
    setIsFreeTrialModalOpen(false);
  };

  const handleConfirmFreeTrialModal = async () => {
    setIsProcessing(true);
    await createInitiativeFromTemplateV2({ templateId, templateName: name }, handleOnError);
  };

  return (
    <Fragment>
      <footer className={styles.footerBg}>
        <div className={styles.footer}>
          <div className={styles.footerInfo}>
            <h2>
              <span>
                {t("Use our BuySmart")} {name} {t("template")}
              </span>{" "}
              {t("packed with Gartner insights to survey and score vendors.")}
            </h2>
            <TypographyComponent rootClassName={styles.inside} styling="p3">
              {t("Inside this template")}
            </TypographyComponent>
            <ul>
              <li>
                <TypographyComponent color="carbon" boldness="semi">
                  {productCount}
                </TypographyComponent>{" "}
                {t("Vendors")}
              </li>
              <li>
                <TypographyComponent color="carbon" boldness="semi">
                  {requirementCount}
                </TypographyComponent>{" "}
                {t("Requirements")}
              </li>
              <li>
                <TypographyComponent color="carbon" boldness="semi">
                  {checklistCount}
                </TypographyComponent>{" "}
                {t("Checklist tasks")}
              </li>
            </ul>
            <TooltipV2
              deactivate={isEntitled || (isTrialUser && userInitCount < 3)}
              placement="top"
              PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
              title={t(
                "Your account does not have permission to create initiatives. Please contact your Gartner Account Manager for assistance."
              )}
            >
              <div className={styles.btnWrapper}>
                <ButtonLoader
                  btnClass="btn-secondary"
                  disabled={!isEntitled && (!isTrialUser || userInitCount >= 3)}
                  isLoading={isProcessing}
                  onClick={handleStartEvalClick}
                >
                  {t("Start Evaluation")}
                </ButtonLoader>
              </div>
            </TooltipV2>
          </div>
          <div className={styles.rightCol}>
            {gcomMqResId !== -1 && (
              <Fragment>
                <img src={MQIconSVG} alt="" />
                <TypographyComponent color="coal" styling="p3">
                  {t(
                    "This template is powered by the Magic Quadrants (MQs) which offer visual snapshots and in-depth analyses that provide insight into a market's direction, maturity and participants."
                  )}
                </TypographyComponent>
              </Fragment>
            )}
            {gcomMgResId !== -1 && (
              <Fragment>
                <img src={MGIconSVG} alt="" />
                <TypographyComponent styling="p3">
                  {t(
                    "Market Guides (MGs) deliver a clear definition and framing of the market, market dynamics and an overview of market participants. They deliver leaders who are considering an investment with actionable insights on use cases spurring the market, offerings and market direction."
                  )}
                </TypographyComponent>
              </Fragment>
            )}
          </div>
        </div>
      </footer>
      <SnackbarBanner
        autoHideDuration={5000}
        isOpen={isError}
        message={t("Something went wrong. Please try again.")}
        setIsOpen={handleErrorClose}
        type="ERROR"
      />
      <FreeTrialInitCreateModalComponent
        currentInits={userInitCount}
        isOpen={isFreeTrialModalOpen}
        isProcessing={isProcessing}
        onClickClose={handleCloseFreeTrialModal}
        onClickConfirm={handleConfirmFreeTrialModal}
      />
    </Fragment>
  );
};

export default Footer;

import React from "react";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  SlideInOverlay,
  TextLink,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import classes from "./research-slide-in.styles.module.scss";
import { ReactElementLike } from "prop-types";
import classNames from "classnames";
import { StarV2Icon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import MagicQBody from "./components/magic-q-body.component";
import MarketGuideBody from "./components/market-guide-body.component";
import ResearchSlideInHeaderLogoComponent from "./components/research-slide-in-header-logo.component";
import { GCOM_3606__fontUpdate, GCOM_3695_ctaButtonColorUpdate } from "../../feature-flags";

const ResearchSlideIn = ({
  isDrawerOpen = false,
  handleOnClick = () => {},
  isMagicQ = false,
  mqResId = -1,
  mgResId = -1,
  piMarketSeoName = "",
  templateName = "",
}) => {
  const { t } = useTranslation();
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);

  return (
    <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
      <FeatureFlagBooleanOn>
        <SlideInOverlay
          isOpen={isDrawerOpen}
          onClose={handleOnClick}
          darth={true}
          textBackBtn={t("eval")}
          headerLogo={(<ResearchSlideInHeaderLogoComponent />) as ReactElementLike}
        >
          <section>
            <TypographyComponent rootClassName={classNames(classes.contentHeader)} styling={"h2"}>
              {templateName}
            </TypographyComponent>
            <div className={classNames(classes.contentHeaderLine)} />
            <div>
              <div>
                <TypographyComponent
                  rootClassName={classNames(
                    classes.contentBodyGartnerResearchTitle,
                    isFFGCOM3695 && classes.contentBodyGartnerResearchTitleGCOM3695
                  )}
                  styling={"h3"}
                >
                  {t("Gartner research behind this market")}
                </TypographyComponent>
                <TypographyComponent element={"p"} rootClassName={classNames(classes.contentBodyGartnerResearchDesc)}>
                  {t(
                    "BuySmart combines the power of curated Gartner research, peer and expert insights, and intuitive workflow processes into markets. Each market is based on Gartner research that uses our proprietary methodologies and an independent operating model for objective business and technology insights."
                  )}
                </TypographyComponent>
                <TypographyComponent element={"p"} rootClassName={classNames(classes.contentBodyGartnerResearchDesc)}>
                  {t(
                    "This market has been created from a variety of published Gartner research documents and data sources."
                  )}
                </TypographyComponent>
              </div>
              <div className={classNames(classes.contentBodyMidSection)}>
                {isMagicQ ? (
                  <MagicQBody documentQuery={mqResId} templateName={templateName} />
                ) : (
                  <MarketGuideBody documentQuery={mgResId} templateName={templateName} />
                )}
                <div className={classNames(classes.contentBodyMidSectionPeerInsights)}>
                  <TypographyComponent
                    styling={"h5"}
                    rootClassName={classNames(
                      classes.contentBodyMidSectionTitleText,
                      isFFGCOM3695 && classes.contentBodyMidSectionTitleTextGCOM3695
                    )}
                  >
                    <StarV2Icon
                      height={"18px"}
                      width={"19px"}
                      fillPath={isFFGCOM3695 ? colorPalette.interactions.berry.hex : colorPalette.brand.surf.hex}
                    />
                    {t("Peer Insights")}
                  </TypographyComponent>
                  <TypographyComponent
                    styling={"p2"}
                    rootClassName={classNames(
                      classes.contentBodyMidSectionDescText,
                      classes.gxGartnerMqMgPiDescTextRight
                    )}
                  >
                    {t(
                      "Peer Insights is Gartners peer-driven platform where thousands of enterprise leaders connect with each other to help guide technology and business decisions. You can access peer technology rating and reviews by verified users, network, share knowledge, get advice, and stay on top of current trends in this technology space."
                    )}
                  </TypographyComponent>
                  <div className={classNames(classes.contentLearnMoreLine)} />
                  <TypographyComponent element={"p"} rootClassName={classNames(classes.contentBodyMidSectionLearnMore)}>
                    {t("Learn more")}
                  </TypographyComponent>
                  <div>
                    <TextLink
                      rootClassName={classNames(classes.contentBodyMidSectionLinkText)}
                      href={"https://www.gartner.com/reviews/market/" + piMarketSeoName}
                      target="_blank"
                      text={t(`Peer Insights for ${templateName}`)}
                      variant={"dark"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </SlideInOverlay>
      </FeatureFlagBooleanOn>
      <FeatureFlagBooleanOff>
        <SlideInOverlay
          isOpen={isDrawerOpen}
          onClose={handleOnClick}
          darth={true}
          textBackBtn={t("eval")}
          headerLogo={(<ResearchSlideInHeaderLogoComponent />) as ReactElementLike}
        >
          <section className={classNames(classes.container)}>
            <h2 className={classNames(classes.contentHeader)}> {templateName} </h2>
            <div className={classNames(classes.contentHeaderLine)} />
            <div className={classNames(classes.contentBody)}>
              <div className={classNames(classes.contentBodyGartnerResearch)}>
                <h3
                  className={classNames(
                    classes.contentBodyGartnerResearchTitle,
                    isFFGCOM3695 && classes.contentBodyGartnerResearchTitleGCOM3695
                  )}
                >
                  {t("Gartner research behind this market")}
                </h3>
                <p className={classNames(classes.contentBodyGartnerResearchDesc)}>
                  {t(
                    "BuySmart combines the power of curated Gartner research, peer and expert insights, and intuitive workflow processes into markets. Each market is based on Gartner research that uses our proprietary methodologies and an independent operating model for objective business and technology insights."
                  )}
                </p>
                <p className={classNames(classes.contentBodyGartnerResearchDesc)}>
                  {t(
                    "This market has been created from a variety of published Gartner research documents and data sources."
                  )}
                </p>
              </div>
              <div className={classNames(classes.contentBodyMidSection)}>
                {isMagicQ ? (
                  <MagicQBody documentQuery={mqResId} templateName={templateName} />
                ) : (
                  <MarketGuideBody documentQuery={mgResId} templateName={templateName} />
                )}
                <div className={classNames(classes.contentBodyMidSectionPeerInsights)}>
                  <h5
                    className={classNames(
                      classes.contentBodyMidSectionTitleText,
                      isFFGCOM3695 && classes.contentBodyMidSectionTitleTextGCOM3695
                    )}
                  >
                    <StarV2Icon
                      height={"18px"}
                      width={"19px"}
                      fillPath={isFFGCOM3695 ? colorPalette.interactions.berry.hex : colorPalette.brand.surf.hex}
                    />
                    {t("Peer Insights")}
                  </h5>
                  <p
                    className={classNames(
                      classes.contentBodyMidSectionDescText,
                      classes.gxGartnerMqMgPiDescTextRight,
                      "p2"
                    )}
                  >
                    {t(
                      "Peer Insights is Gartners peer-driven platform where thousands of enterprise leaders connect with each other to help guide technology and business decisions. You can access peer technology rating and reviews by verified users, network, share knowledge, get advice, and stay on top of current trends in this technology space."
                    )}
                  </p>
                  <div className={classNames(classes.contentLearnMoreLine)} />
                  <p className={classNames(classes.contentBodyMidSectionLearnMore)}>{t("Learn more")}</p>
                  <div className={classNames(classes.contentLink)}>
                    <TextLink
                      rootClassName={classNames(classes.contentBodyMidSectionLinkText)}
                      href={"https://www.gartner.com/reviews/market/" + piMarketSeoName}
                      target="_blank"
                      text={t(`Peer Insights for ${templateName}`)}
                      variant={"dark"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </SlideInOverlay>
      </FeatureFlagBooleanOff>
    </FeatureFlagBooleanContainer>
  );
};

export default ResearchSlideIn;

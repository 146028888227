import { StarSmallIcon } from "gx-npm-icons";
import { TooltipV2, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import styles from "./peer-insights-cell.styles.module.scss";

type PeerInsightsCellProps = { rating: number; reviewCount: number };
const PeerInsightsCell = ({ rating = 0, reviewCount = 0 }: PeerInsightsCellProps) => {
  const hasReviews = reviewCount > 0;
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      {!hasReviews && (
        <TooltipV2
          placement="left"
          PopperProps={{ modifiers: { offset: { offset: "0, 12px, 264px" } } }}
          title={t("There are no user reviews for this product in this market, so we are unable to provide a rating.")}
        >
          <div aria-label="no peer reviews">
            <TypographyComponent boldness="semi" color="iron" type="p4">
              {t("No rating")}
            </TypographyComponent>
          </div>
        </TooltipV2>
      )}
      {hasReviews && (
        <div aria-label="peer review rating" className={styles.piRating}>
          <StarSmallIcon />
          <TypographyComponent boldness="semi" color="carbon" type="p1">
            {(Math.floor(rating * 10) / 10 || 0).toFixed(1)}
          </TypographyComponent>
          <TypographyComponent boldness="regular" color="iron" rootClassName={styles.piDenominator} type="p4">
            {" / 5"}
          </TypographyComponent>
        </div>
      )}
      <div aria-label="peer review count">
        <TypographyComponent boldness="regular" color="iron" type="p4">
          {`${reviewCount || "0"} ${t("reviews")}`}
        </TypographyComponent>
      </div>
    </div>
  );
};

export default PeerInsightsCell;

import { colorPalette } from "gx-npm-common-styles";
import { CloseIcon } from "gx-npm-icons";
import { postAsyncRequest, PriorityWeightOption, useCaptureEventsV2 } from "gx-npm-lib";
import {
  Button,
  MiniButton,
  SnackbarBanner,
  TextLink,
  TooltipV2,
  TypographyComponent,
  useFeatureFlag,
  WeightedPriority,
} from "gx-npm-ui";
import { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientEvent } from "../../../app.constants";
import { MarketProfileAppContext } from "../../../app.context";
import PrioritiesBannerImg from "../../../assets/images/priorities-banner.svg";
import styles from "./priorities-banner.styles.module.scss";
import { GCOM_3695_ctaButtonColorUpdate } from "../../../lib/feature-flag";
import classNames from "classnames";

const PrioritiesBannerComponent = () => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const { t } = useTranslation();
  const [isErrorClear, setIsErrorClear] = useState(false);
  const [isSuccessClear, setIsSuccessClear] = useState(false);
  const captureEvents = useCaptureEventsV2();
  const {
    featuresAndFunctionalities,
    isProductListLoading,
    templateId,
    setHasSelectedPriorities,
    setIsProductListLoading,
    setIsWeightedPriorityDrawerOpen,
    setProducts,
  } = useContext(MarketProfileAppContext);

  const selectedOptions = [PriorityWeightOption.LOW, PriorityWeightOption.MEDIUM, PriorityWeightOption.HIGH];
  const selectedPriorities = JSON.parse(sessionStorage.getItem(`weightedPriorities-${templateId}`) || "[]")
    .filter((item: WeightedPriority) => selectedOptions.includes(item.weight))
    .map((item: WeightedPriority) => featuresAndFunctionalities.find((feature) => feature.id === item.id)?.name || "")
    .filter((item: string) => item !== "");
  const priorityCount = selectedPriorities.length;

  const handleClickClear = async () => {
    setIsProductListLoading(true);
    const url = `/api/v2/data/market-profile/${templateId}/products-ranked`;
    const response = await postAsyncRequest(url, { weightedPriorities: [] });
    if (response.status !== 201 || !Array.isArray(response.data?.data?.products)) {
      setIsErrorClear(true);
      setIsProductListLoading(false);
      return;
    }
    setHasSelectedPriorities(false);
    setProducts(response.data.data.products);
    setIsSuccessClear(true);
    sessionStorage.removeItem(`weightedPriorities-${templateId}`);
    captureEvents([
      {
        eventType: ClientEvent.MARKET_PROFILE_PRODUCT_PRIORITIES_CHANGED,
        metaData: { priorities: [], templateId: templateId.toString() },
      },
    ]);
    setIsProductListLoading(false);
  };

  const handleClickOpen = () => {
    setIsWeightedPriorityDrawerOpen(true);
  };

  return (
    <Fragment>
      <div className={styles.prioritiesBanner}>
        <div>
          <TypographyComponent rootClassName={styles.bannerTitle} boldness="semi" type="p1">
            {t("Set your priorities and see how well each vendor fits your needs.")}
          </TypographyComponent>
          <TextLink
            href="https://intercom.help/gartner-buysmart-help-center/en/articles/9000863-vendor-fit"
            text={t("Learn more")}
            target="_blank"
            variant="secondary"
          />
        </div>
        <img className={styles.bannerSvg} alt="" src={PrioritiesBannerImg} />

        <div className={styles.buttonsWrapper}>
          <TooltipV2
            deactivate={priorityCount === 0}
            placement="top"
            PopperProps={{ modifiers: { offset: { offset: "0, 5px" } } }}
            title={`${t("The following priorities are selected")}: ${selectedPriorities.join(", ")}`}
          >
            <div>
              <Button onClick={handleClickOpen} rootClassName="btn-primary">
                {priorityCount > 0 ? t("Edit Priorities") : t("Set Priorities")}
                {priorityCount > 0 && (
                  <div className={styles.countBadgeWrapper}>
                    <TypographyComponent
                      color="blueBerry"
                      type="p3"
                      boldness="semi"
                      rootClassName={classNames(styles.countBadge, isFFGCOM3695 && styles.countBadgeGCOM3695)}
                    >
                      {priorityCount}
                    </TypographyComponent>
                  </div>
                )}
              </Button>
            </div>
          </TooltipV2>
          {priorityCount > 0 && (
            <TooltipV2
              placement="top"
              PopperProps={{ modifiers: { offset: { offset: "0, 5px" } } }}
              title={t("Clear all priority settings")}
            >
              <div>
                <MiniButton disabled={isProductListLoading} onClick={handleClickClear}>
                  <CloseIcon fillPath={colorPalette.interactions.blueBerry.hex} />
                  {t("Clear all")}
                </MiniButton>
              </div>
            </TooltipV2>
          )}
        </div>
      </div>
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={isErrorClear} setIsOpen={setIsErrorClear} />
      <SnackbarBanner
        isOpen={isSuccessClear}
        message={t("Your priorities have been removed.")}
        setIsOpen={setIsSuccessClear}
        type="SUCCESS"
      />
    </Fragment>
  );
};

export default PrioritiesBannerComponent;

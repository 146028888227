import { useTranslation } from "react-i18next";
import { PriorityWeightOption } from "gx-npm-lib";
import { TypographyComponent } from "../../../typography/typography.component";
import LowWeightIndicator from "../../assets/low-weight-indicator.svg";
import NoWeightIndicator from "../../assets/no-weight-indicator.svg";
import HighWeightIndicator from "../../assets/high-weight-indicator.svg";
import MediumWeightIndicator from "../../assets/medium-weight-indicator.svg";
import styles from "./weight-selection.styles.module.scss";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn } from "../../../featureFlags";
import { GCOM_3606__fontUpdate } from "../../../feature-flags";

type WeightSelectionBarProps = { weight: PriorityWeightOption };
const WeightSelection = ({ weight = PriorityWeightOption.NONE }: WeightSelectionBarProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {weight === PriorityWeightOption.NONE && (
        <div className={styles.textImgWrapper}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent boldness="semi" color="iron" styling={"p3"}>
                {t("Not a priority")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent boldness="semi" color="iron" type={"p3"}>
                {t("Not a priority")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          <img className={styles.indicator} alt="" src={NoWeightIndicator} />
        </div>
      )}
      {weight === PriorityWeightOption.LOW && (
        <div className={styles.textImgWrapper}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent boldness="semi" color="carbon" styling={"p3"}>
                {t("Low priority")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent boldness="semi" color="carbon" type={"p3"}>
                {t("Low priority")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>

          <img className={styles.indicator} alt="" src={LowWeightIndicator} />
        </div>
      )}
      {weight === PriorityWeightOption.MEDIUM && (
        <div className={styles.textImgWrapper}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent boldness="semi" color="carbon" styling={"p3"}>
                {t("Medium priority")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent boldness="semi" color="carbon" type={"p3"}>
                {t("Medium priority")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>

          <img className={styles.indicator} alt="" src={MediumWeightIndicator} />
        </div>
      )}
      {weight === PriorityWeightOption.HIGH && (
        <div className={styles.textImgWrapper}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent boldness="semi" color="carbon" styling={"p3"}>
                {t("High priority")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent boldness="semi" color="carbon" type={"p3"}>
                {t("High priority")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          <img className={styles.indicator} alt="" src={HighWeightIndicator} />
        </div>
      )}
    </div>
  );
};
export default WeightSelection;

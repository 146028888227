import { Fade } from "@mui/material";
import classNames from "classnames";
import { useUserState } from "gx-npm-lib";
import { FreeTrialBannerComponent, TypographyComponent } from "gx-npm-ui";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MarketProfileAppContext } from "../../app.context";
import MQIconV3SVG from "../../assets/images/mq-icon3.svg";
import MGIconV3SVG from "../../assets/images/mg-icon3.svg";
import ActiveEvalNotification from "./active-eval-notification/active-eval-notification.component";
import BreadcrumbNavV3Component from "./breadcrumb-nav-v3/breadcrumb-nav-v3.component";
import HeaderActionButtonsComponent from "./header-action-buttons/header-action-buttons.component";
import MarketResearchRibbonComponent from "./market-research-ribbon/market-research-ribbon.component";
import { HeaderPosition } from "./header-v3.constants";
import styles from "./header-v3.styles.module.scss";

const SHOW_SCROLL_HEADER_HEIGHT = 108;
const HeaderV3Component = () => {
  const { t } = useTranslation();
  const { checklistCount, name, productCount, requirementCount, gcomMgResId, gcomMqResId, latestExistingInitId } =
    useContext(MarketProfileAppContext);
  const { freeTrialDaysRemaining, freeTrialRole } = useUserState();
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(window.scrollY > SHOW_SCROLL_HEADER_HEIGHT);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setIsScrolling]);

  return (
    <Fragment>
      {freeTrialRole && (
        <FreeTrialBannerComponent daysRemaining={freeTrialDaysRemaining} freeTrialRole={freeTrialRole} />
      )}
      <header aria-hidden={isScrolling} className={styles.staticTopHeader}>
        <ActiveEvalNotification latestExistingInitId={latestExistingInitId} />
        <div className={styles.staticTopWrapper}>
          <div className={styles.detailsContainer}>
            {gcomMqResId > 0 && <img alt={t("magic quadrant icon")} src={MQIconV3SVG} />}
            {gcomMgResId > 0 && <img alt={t("market guide icon")} src={MGIconV3SVG} />}
            <div className={styles.detailsWrapper}>
              <TypographyComponent rootClassName={styles.detailsTitle} color="white" boldness="semi" styling="p1">
                {`${name} ${t("evaluation template")}`}
              </TypographyComponent>
              <ul className={styles.countList}>
                <li className={styles.countItem}>
                  <TypographyComponent boldness="semi" color="white" styling="p3">
                    {productCount}
                    <TypographyComponent boldness="semi" color="stone" element="span" styling="p4">
                      {` ${t("Vendors")}`}
                    </TypographyComponent>
                  </TypographyComponent>
                </li>
                <li className={styles.countItem}>
                  <TypographyComponent boldness="semi" color="white" styling="p3">
                    {requirementCount}
                    <TypographyComponent boldness="semi" color="stone" element="span" styling="p4">
                      {` ${t("Requirements")}`}
                    </TypographyComponent>
                  </TypographyComponent>
                </li>
                <li className={classNames(styles.countItem, styles.lastCountItem)}>
                  <TypographyComponent boldness="semi" color="white" styling="p3">
                    {checklistCount}
                    <TypographyComponent boldness="semi" color="stone" element="span" styling="p4">
                      {` ${t("Checklist tasks")}`}
                    </TypographyComponent>
                  </TypographyComponent>
                </li>
              </ul>
            </div>
          </div>
          <HeaderActionButtonsComponent headerPosition={HeaderPosition.STATIC_TOP} />
        </div>
        <div className={classNames(styles.researchRibbonContainer, isScrolling && styles.hidden)}>
          {(gcomMgResId > 0 || gcomMqResId > 0) && name && <MarketResearchRibbonComponent />}
        </div>
      </header>
      <div className={styles.navSectionContainer}>
        <div className={styles.navSectionWrapper}>
          <BreadcrumbNavV3Component rootClassName={styles.navBreadcrumbs} />
        </div>
      </div>
      <div className={styles.titleContainer}>
        <TypographyComponent color="carbon" boldness="semi" rootClassName={styles.title} type="h3">
          {name}
        </TypographyComponent>
      </div>
      <Fade timeout={{ enter: 500, exit: 500 }} in={isScrolling}>
        <header role="banner" className={styles.floatingScrolledHeader}>
          <ActiveEvalNotification isScrolling={isScrolling} latestExistingInitId={latestExistingInitId} />
          <div className={styles.mainNavOffSet}>
            <div className={styles.floatingScrolledWrapper}>
              <BreadcrumbNavV3Component />
              <HeaderActionButtonsComponent headerPosition={HeaderPosition.FLOATING_SCROLL} isDisabled={!isScrolling} />
            </div>
          </div>
        </header>
      </Fade>
    </Fragment>
  );
};

export default HeaderV3Component;
